<template>
    <o-data-lookup :data-object="dsLookupDataObject" :whereClause="`'${(props.IdPath?props.IdPath:context.idPath)}' LIKE IdPath + '%'`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
            <span v-else :ref="target" style="cursor:pointer;">                
            </span>
        </template>
        <o-column field="ID" width="80"></o-column>
        <o-column field="Name" width="200"></o-column>
        <o-column field="OrgUnit" width="200"></o-column>
        <o-column field="Checklist" width="130"></o-column>
        <o-column field="Revision" width="100"></o-column>
    </o-data-lookup>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365.vue.ts'
    import context from "o365.modules.Context.ts";

    const props = defineProps({
        is: String,
        IdPath: String,      
        textInputValue: String,
        textInput: Boolean
    });

    const dsLookupDataObject = getOrCreateDataObject({
        id: 'dsLookupDataObject' + crypto.randomUUID(),
        viewName: 'aviw_Complete_HandoverTypesLookup',
        maxRecords: 25,
        whereClause: "",
        loadRecents: false,
        distinctRows: true,
        fields:
             [{name: "ID", type: "number" },
             {name: "Name", type: "string" },             
             {name: "Checklist", type: "string" },              
             {name: "Revision", type: "number" },
             {name: "OrgUnit", type: "string" },
             {name: "Checklist_ID", type: "number" },
             {name: "CompletionPhase_ID", type: "number" },
             {name: "CompletionPhase", type: "string" },
             {name: "ActivityType_ID", type: "number" },
             {name: "ActivityType", type: "string" },]
    });
   
</script>